import * as styles from './style.css'
import iconCompany from '~/assets/img/icon-company.svg'

type Props = {
  className?: string
  src?: string
  alt?: string
  size?: 'small' | 'medium'
  tag?: keyof JSX.IntrinsicElements
}

export const CompanyIcon = ({ className, src, alt, size = 'medium', tag }: Props) => {
  const Tag = tag ?? 'figure'

  return (
    <Tag
      className={[styles.companyIcon, styles[`companyIcon__${size}`], !src && styles.companyIcon__empty, className]
        .filter(Boolean)
        .join(' ')}
    >
      <img src={src ?? iconCompany} alt={alt} className={styles.companyIcon_image} />
    </Tag>
  )
}
